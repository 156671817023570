import React, { useEffect, useState } from "react";
import backgroundImage from "../public/Images/CoursesBg.jpg"; // Assuming the image path is correct
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CoursesSection = () => {
  const [courses, setCourses] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { t, i18n } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalToggle = () => {
    setModalOpen(!isModalOpen);
  };

  const fetchCourses = async () => {
    const params = {
      dataLimit: 1,
    };
    try {
      const response = await axios.get(`${BASE_URL}courses`, {
        params,
        headers: {
          "Accept-Language": i18n.language,
        },
      });
      setCourses(response?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [i18n.language]);

  return (
    <section
      className="min-h-screen bg-cover bg-center lg:grid md:grid grid-cols-12 place-content-center rtl:text-right ltr:text-left"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <div className="2xl:col-span-6 xl:col-span-6  lg:col-span-6 md:col-span-6 col-span-12 mx-auto 2xl:mx-6 xl:mx-6 lg:mx-6 md:mx-4 px-4 2xl:my-12 xl:my-12 lg:my-12 md:my-8  my-12 2xl:text-justify xl:text-justify lg:text-justify md:text-justify text-center">
        <p className="text-[#333333] xl:text-5xl lg:text-5xl md:text-5xl text-4xl font-bold text-center">
          {courses[0]?.name}
        </p>
        <p className="text-[#666666] mb-6 2xl:text-xl xl:text-lg lg:text-lg md:text-base text-base font-semibold">
          {courses[0]?.details}
        </p>
        <div className="lg:flex md:flex items-center justify-center gap-x-6 ">
          <button
            className="empty-button my-4 "
            style={{
              borderRadius: "25px",
              minWidth: "250px",
              padding: "12px 20px",
            }}
            onClick={handleModalToggle} // Toggle modal on button click
          >
            <span className="hover:text-white transition-all duration-200 xl:text-lg lg:text-lg md:text-lg text-base">
              {t("courses.detailsButton")}
            </span>
          </button>
          <button
            className="empty-button my-4 "
            style={{
              borderRadius: "25px",
              minWidth: "250px",
              padding: "12px 20px",
            }}
          >
            <Link
              to={`/${i18n.language}/courses`}
              className="hover:text-white transition-all duration-200 xl:text-lg lg:text-lg md:text-lg text-base"
            >
              {t("courses.viewMore")}
            </Link>
          </button>

          {/* Modal */}
          {isModalOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-4"
              onClick={handleModalToggle} // Close modal when clicking outside the video
            >
              <div
                className="bg-white p-4 rounded-md"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the video
              >
                <video
                  width="600"
                  controls
                  src={`${process.env.REACT_APP_MAIN_URL}/storage/${courses[0]?.promotional_video}`} // Replace with your actual video path
                  className="rounded-lg"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="2xl:col-span-6 xl:col-span-6  lg:col-span-6 md:col-span-6 col-span-12 mx-auto text-center ">
        <img
          src={`${process.env.REACT_APP_MAIN_URL}/storage/${courses[0]?.image}`}
          alt="courses_image"
          className="h-full object-cover"
        />
      </div>
    </section>
  );
};

export default CoursesSection;
